import React from 'react'
import Carousel from '../../Layout/Carousel/Carousel'
import { Row, Container, Col, Button } from 'react-bootstrap'
import {Link} from "react-router-dom";


class ApartmentCard extends React.Component {
    structureTags() {
        const tags = [
            `${this.props.apartment.betten} Betten`,
            this.beautifyEtageTag(this.props.apartment.etage),
            this.props.apartment.zusatz_1,
            this.props.apartment.zusatz_2,
            this.props.apartment.zusatz_3,
            this.props.apartment.zusatz_4,
            this.props.apartment.zusatz_5,
            this.props.apartment.zusatz_6
        ];

        return tags.filter(tag => tag != null);
    }

    beautifyEtageTag(floorTag){
        if(floorTag === "DG"){
            return "Dachgeschoss"
        } else if(floorTag === "0"){
            return "Erdgeschoss"
        } else {
            return floorTag + ". Etage"
        }
    }

    renderTags(){
        const tags = this.structureTags()
        return tags.map((tag, index) => (
                <React.Fragment key={tag + "_" + index}>
                    <Button size="sm" variant="light" style={{marginTop: "4px"}}>
                        {tag}
                    </Button> {" "}
                </React.Fragment>
            )
        )
    }

    render() {
        return (
          <div className="apartmentCard_container hvr-shrink">
            <Link to={"/apartment/" + this.props.apartment.id}>
                <Carousel
                    maxImageCount = {4}
                    apartment={this.props.apartment}/>
            </Link>
                <p className="apartmentCard_title" key={this.props.apartment.id + "_title"}>
                    {this.props.apartment.name}
                </p>
            <Container fluid>

                { this.renderTags() }

                <Row>
                    <Col md={6}>
                        <p className="apartmentCard_preis"> {this.props.apartment.preisProPerson} p. Person</p>
                    </Col>
                    <Col md={6}>
                        <Link to={"/apartment/" + this.props.apartment.id}>
                            <Button
                                variant="outline-primary"
                                className="apartmentCard_button">
                                Anschauen
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
          </div>
        )
    }
}

export default ApartmentCard
