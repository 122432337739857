import React from "react";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import "./datePicker_lea.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from 'date-fns/locale/de'; // Import der deutschen Locale von date-fns

class DatePickerLea extends React.Component {

    componentDidMount() {
        registerLocale('de', de);
        setDefaultLocale('de');
    }

    handleChange(value, event){
        // setzt id anhand der parent prop und value anhand des gegebenen values
        event.target.id = this.props.id;
        event.target.value = value;
        event.target.type = 'datePicker';
        this.props.handleChange(event);
    }

    render(){
        return(
            <DatePicker
                selected={this.props.state}
                closeOnScroll={true}
                minDate={this.props.minDate ? this.props.minDate : new Date()}
                onChange={(value, event) => this.handleChange(value, event)}
                customInput={
                    <Button
                        size="sm"
                        block
                        variant="outline-secondary">
                        {this.props.title}: {this.props.state && this.props.state.toLocaleDateString('de-DE', {year: "numeric", month: "2-digit", day: "2-digit"})}
                    </Button>
                }
                locale="de"
            />
        )
    }
}

export default DatePickerLea;
