import React from 'react'

import stock from "./stock.jpg"
import "./employeecard.css"

import {Image} from 'react-bootstrap'

const EmployeeCard = (props) => {
    const styles = {
      fontSize : "1.5em",
      paddingTop: "2%"
    }

    return (
      <div className="employee hvr-grow-shadow">
        <Image src={props.img ? props.img : stock} fluid rounded/>
        <p style={styles} className="text-center">{props.name}</p>
        <p style={styles} className="text-center">{props.job}</p>
      </div>
    )
}

export default EmployeeCard
