import React from "react"
import { AspectRatio } from "@chakra-ui/react"

const Map = (props) => {
  return (
    <AspectRatio ratio={21 / 9}>
      <iframe
        title ={props.title}
        src={props.src}
        alt="demo"
      />
    </AspectRatio>
  )
}

export default Map
