import React from "react"
import Layout from "./Layout/Layout";
import "./store.css"
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import firebaseConfiguration from "../data/firebaseConfig.json"

class Store extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cookiesAccepted: false,
            stadtteil: 'Leipzig',
            personenAnzahl: 1,
            zimmerAnzahl: 1,
            bettenAnzahl: 1,
            aufenthaltsdauer: 2,
            aufenthaltsdauerMin: 2,
            aufenthaltsdauerMax: 365,
            anreise: new Date(),
            abreise: new Date(Date.now() + 14 * 24*60*60*1000), // heute + 2 Wochen
            data: [],
        }
        this.handleChange = this.handleChange.bind(this);
        this.filterData = this.filterData.bind(this);
        this.setAbreiseDatum = this.setAbreiseDatum.bind(this);
        this.initialized = false;
    }

    async componentDidMount(){
        const data = [];
        this.initializeFirebaseConnection()
            .collection('apartment')
            .get()
            .then((snapshot) => {
                snapshot.forEach(function(doc) {
                    if(doc.data().id !== ""){
                        data.push(doc.data())
                    }
                });
                data.sort(this.compareZimmerAnzahl) // Initialwohnungen die angezeigt werden: die mit meisten Zimmern
                this.setState({data: data})
            })
    }

    initializeFirebaseConnection(){
        !this.initialized && firebase.initializeApp(firebaseConfiguration);
        return firebase.firestore()
    }

    handleChange(event){
        const {id, type, checked, value} = event.target
        if (type === 'checkbox'){
            this.setState({[id]:checked})
        } else {
            this.setState({[id]:value})
        }
        if (type === 'datePicker'){
            this.setAbreiseDatum(value)
        }

        if(id === "personenAnzahl"){
            if(value < 7){
                this.setState({bettenAnzahl: value})
                this.setState({zimmerAnzahl: parseInt(value/3)})
            } else { // value > 7
                if(value < 42){
                    this.setState({zimmerAnzahl: parseInt(value/3)})
                } else {
                    this.setState({zimmerAnzahl: parseInt(13)})
                }
                this.setState({bettenAnzahl: 7})
            }
        }
        this.filterData(event)
    }

    filterData(event){
        let dataFiltered = this.state.data;
        const { id } = event?.target || {};

        switch(id){
            case 'zimmerAnzahl': dataFiltered.sort(this.compareZimmerAnzahl); break;
            case 'bettenAnzahl': dataFiltered.sort(this.compareBettenAnzahl); break;
            case 'map_nord': dataFiltered.sort(this.compareStadtteil('Nord')); break;
            case 'map_sued': dataFiltered.sort(this.compareStadtteil('Süd')); break;
            case 'map_ost': dataFiltered.sort(this.compareStadtteil('Ost')); break;
            case 'map_westen': dataFiltered.sort(this.compareStadtteil('West')); break;
            case 'map_zentrum': dataFiltered.sort(this.compareStadtteil('Zentrum')); break;
            case 'stadtteil': dataFiltered.sort(this.decipherStadteil(event)); break;
            default: dataFiltered.sort(this.compareBilderAnzahl);
        }
        this.setState({data: dataFiltered})
    }

    decipherStadteil(event){
        switch(event.target.value){
            case "Leipzig" : return this.compareBilderAnzahl;
            case "Zentrum" : return this.compareStadtteil('Zentrum');
            case "Nord" : return this.compareStadtteil('Nord');
            case "West" : return this.compareStadtteil('West');
            case "Ost" : return this.compareStadtteil('Ost');
            case"Süd" : return this.compareStadtteil('Süd');
            default: return this.compareBilderAnzahl;
        }
    }

    compareStadtteil(stadtteil) {
        return function (wohnung_a, wohnung_b) {
            const isAStadtteil = wohnung_a.stadtteil_grob === stadtteil;
            const isBStadtteil = wohnung_b.stadtteil_grob === stadtteil;

            if (isAStadtteil && !isBStadtteil) {
                return -1;
            } else if (!isAStadtteil && isBStadtteil) {
                return 1;
            }
            return 0;
        };
    }

    compareBilderAnzahl(wohnung_a, wohnung_b) {
        return wohnung_b.bilder_anz - wohnung_a.bilder_anz;
    }

    compareZimmerAnzahl(wohnung_a, wohnung_b) {
        return wohnung_b.raeume - wohnung_a.raeume;
    }

    compareBettenAnzahl(wohnung_a, wohnung_b) {
        return wohnung_b.betten - wohnung_a.betten;
    }

    setAbreiseDatum(anreiseDatum){
        if(this.state.abreise < anreiseDatum){
            this.setState({ abreise: anreiseDatum });
        }
    }

    render(){
        return (
            <React.Fragment>
                <Layout
                    state={this.state}
                    handleChange={this.handleChange}
                    filterData={this.filterData}
                />
            </React.Fragment>
        )
    }
}

export default Store;
