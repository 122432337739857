import React from 'react'
import {Container, Row, Button} from "react-bootstrap"
import './apartmentPage.css'
import Carousel from '../../Layout/Carousel/Carousel';
import {Link} from "react-router-dom";
import { useEffect } from "react"

const ApartmentPage = (props) => {
    const apartment = props.data.find(data => String(data.id) === String(props.match.params.id));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!apartment) {
        console.error('Apartment mit der ID nicht gefunden:', props.match.params.id);
        return <div>Apartment nicht gefunden.</div>;
    }

    return (
        <Container>
            <Carousel apartment={apartment}/>

            <h3 className="apartmentPage_name">{apartment.name}</h3>
            <hr/>
            <h4>Beschreibung</h4>

            <Row>
                <p className="apartmentPage_tag">{apartment.stadtteil} </p>
                <p className="apartmentPage_tag">{apartment.betten} Betten </p>
                <p className="apartmentPage_tag">{apartment.etage}. Etage </p>
                {apartment.raueme > 1 ?
                    <p className="apartmentPage_tag">{apartment.raueme} Räume </p> :
                    <p className="apartmentPage_tag">{apartment.raueme} Raum </p>
                }
            </Row>

            <p>{apartment.beschreibung ? apartment.beschreibung : ""}</p>

            <hr/>

            <h4>Ausstattung</h4>

            {apartment.zusatz_1 && <p>{apartment.zusatz_1}</p>}
            {apartment.zusatz_2 && <p>{apartment.zusatz_2}</p>}
            {apartment.zusatz_3 && <p>{apartment.zusatz_3}</p>}
            {apartment.zusatz_4 && <p>{apartment.zusatz_4}</p>}
            {apartment.zusatz_5 && <p>{apartment.zusatz_5}</p>}
            {apartment.zusatz_6 && <p>{apartment.zusatz_6}</p>}

            <hr/>

            <h4>Ihre Anfrage</h4>
            <p>Ihre geplante Anreise: {props.anreise.toLocaleDateString('de-DE',{year: "numeric", month: "2-digit", day: "2-digit"})}
                 - Abreise: {props.abreise.toLocaleDateString('de-DE', {year: "numeric", month: "2-digit", day: "2-digit"})} mit
                &nbsp;{props.personenAnzahl} Person(en).
            </p>

            <h6>Preis Pro Nacht (p.Person): </h6>
            <h4>ab {apartment.preisProPerson}</h4>

            <hr/>

            <h4>Kontakt</h4>

            <h5>Rufen Sie einfach an unter der +49 160 966 04 793 oder stellen Sie eine </h5>

            <br/>

            <Link to="/contact">
                <Button className="btn_unverbindlicheAnfrage" variant="primary">Unverbindliche Anfrage</Button>
            </Link>

        </Container>

    )
}

export default ApartmentPage
