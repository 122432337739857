import React from "react"
import "./legalStandard.css"

import {Container} from "react-bootstrap"

const Withdrawal = () =>{

    return (
        <Container className="legalStandard">
            <p style={{fontSize: "4vh", textSizeAdjust: "auto"}} >Widerrufsbelehrung</p>
            <p>Sie haben das Recht, binnen 14 Tagen ohne Angaben von Gründen diesen Vertrag * zu widerrufen.

Die Widerrufsfrist beträgt 14 Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Lea Apartmens Inhaber: Jochen Läßig, Arthur-Hoffmann-Straße 13, 04107 Leipzig, Tel. +4916096604793) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail jochen-laessig@t-online.de) über ihren Entschluss, diesen Vertrag zu widerrufen, informieren.

Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsrist absenden.</p>
            <p style={{fontSize: "2vh", textSizeAdjust: "auto"}} >Folgen des Widerrufs</p>
            <p>Wenn Sie diesen Vertrag widerrufen, haben wir ihnen alle Zahlungen, die wir von ihnen erhalten haben, unverzüglich und spätestens binnen 14 Tagen ab dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit ihnen wurde ausdrücklich etwas anderes vereinbart; in keinen Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
        </Container>
    )
}


export default Withdrawal
