import React from 'react'

import ContactForm from './ContactForm.js'
import Map from './Map.js'
import ContactJochen from './ContactJochen.js'
import {Container, Row, Col } from 'react-bootstrap'

import "./contact.css"


class Contact extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render()  {
    return (
      <>
      <Container>
          <div className="margin_small"></div>
          <Row>
              <Col><ContactJochen /></Col>
          </Row>
          <div className="margin_large"></div>
          <Row>
              <Col><ContactForm state={this.props.state}/></Col>
          </Row>
      </Container>
      <div className="margin_large"></div>
      <Container>
        <Map
            title="Jochen's place"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2493.092465369545!2d12.377686315763388!3d51.32781907960597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6f83230643091%3A0xdebe55e5f5d5ccfc!2sArthur-Hoffmann-Stra%C3%9Fe%2013%2C%2004107%20Leipzig!5e0!3m2!1sde!2sde!4v1611055347803!5m2!1sde!2sde" />
      </Container>
      </>
    )
    }

}

export default Contact
