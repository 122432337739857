import React from 'react'


import "./benefitscard.css"
import { Card} from 'react-bootstrap'

const BenefitsCard = (props) => {

    return (
      <Card className="benefitsCard">
        <Card.Header style={{fontSize : "1.5em"}}>
          {props.icon}  {props.title}
        </Card.Header>
        <Card.Body>
          <Card.Text>{props.description}</Card.Text>
        </Card.Body>
      </Card>
    )
}

export default BenefitsCard
