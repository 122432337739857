import React from 'react'
import { Image, Container, Row, Col } from 'react-bootstrap'
import DataCard from './DataCard.js'
import Rathaus from '../../../assets/img/layout/rathaus.jpg'
import './MarketingArea.css'
import BenefitsCard from '../AboutUs/BenefitsCard'
import {
    CreditCard2Back,
    EmojiSmile,
    FilePerson,
    HourglassTop,
    ReceiptCutoff,
    TelephoneInbound
} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

class MarketingArea extends React.Component {
    render() {
        return (
                <Container className="home_marketingArea" fluid>

                    <Row>
                        <Col md>
                            <div className="marketingArea_ImageBox">
                                <Image src={Rathaus} className="marketingArea_image" fluid/>
                            </div>
                        </Col>
                        <Col lg>
                            <div className="marketingArea_text">
                                <h1 className="marketingArea_heading">Zahlen & Fakten</h1>
                                <p className="marketingArea_introText">
                                    Jedes Aparment verfügt über eine <b>Waschmaschine, WLAN, Smart-TV</b> sowie eine komplette
                                    <b> Küchenaustattung</b> (inkl. Mikrowelle, Kaffeemaschine, Ofen, Herd).
                                </p>
                                <br/>
                                <Container>
                                    <Row>
                                        <Col>
                                            <DataCard
                                                text="Wohnungen"
                                                number="60"
                                                icon="apartment" />
                                        </Col>
                                        <Col>
                                            <DataCard
                                                text="Zimmer"
                                                number="154"
                                                icon="rooms" />

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DataCard
                                                text="Betten"
                                                number="214"
                                                icon="beds" />

                                        </Col>
                                        <Col>
                                            <DataCard
                                                text="Personen"
                                                number="240"
                                                icon="people" />
                                        </Col>
                                    </Row>
                                </Container>

                            </div>
                        </Col>
                    </Row>

                    <Container> {/* Welcome Cards*/}
                        <h2 className="marketingArea_heading">Herzlich Willkommen</h2>
                        <Row>
                            <Col md={4}>
                                <Link to="/aboutus" className="signlessLink">
                                <BenefitsCard title="Wer wir sind"
                                              description="Die Fa. LEA Leipzig-Apartments Leipzig vermietet Ihnen im Stadtgebiet von Leipzig
                                                            insgesamt 60 Wohnungen unterschiedlicher Größe von 1-Raum-Studios für 1 bis 3
                                                            Personen über 2 Raum-Apartments für 3 bis 5 Personen bis hin zu 3-Raumwohnungen für
                                                            maximal 7 Personen."
                                              icon={<FilePerson/>}/>
                                </Link>
                            </Col>
                            <Col md={4}>
                                <BenefitsCard title="Unsere Preise"
                                              description="Die Preise für die Übernachtung von Monteuren variieren je nach Dichte der Belegung,
                                              Jahreszeit, Komfort und Dauer zwischen 11 EUR und 20 EUR für mehrere Personen und 25 bis 35  EUR für
                                              Einzelreisende pro Nacht und Person."
                                              icon={<CreditCard2Back/>}/>
                            </Col>
                            <Col md={4}>
                                <BenefitsCard title="Ausstattung"
                                              description="In allen Wohnungen sind getrennte Betten, voll ausgestattete Küchen, Internet und Waschmachine vorhanden. Einige Wohnungen besitzen  Tiefgarage und Balkon."
                                              icon={<ReceiptCutoff/>}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <BenefitsCard title="Zukunft Leipzigs"
                                              description="  In den nächsten Jahren entstehen in Leipzig weitere große Baustellen, Sie können bei uns Ihre
                                                                Mitarbeiter in der Nähe der  Baustelle im Zentrum, Zentrum-Süd, Plagwitz,  Lindenau oder Zentrum Ost unterbringen."
                                              icon={<HourglassTop/>}/>
                            </Col>
                            <Col md={4}>
                                <BenefitsCard title="Wir unterstützen gern"
                                              description="Die Firmen BMW, Amazon, Porsche und DHL ziehen immer mehr Beschäftigte an, die bei uns von
                                              einem Monat bis zu einem Jahr komfortabel untergebracht werden. Für diese Mitarbeiter haben wir im Zentrum Nord,
                                              Zentrum Nordwest, Eutritzsch, Volkmarsdorf oder Schönefeld attraktive Unterkünfte."
                                              icon={<EmojiSmile/>}/>
                            </Col>

                            <Col md={4}>
                                <Link to="/contact" className="signlessLink">
                                    <BenefitsCard title="So erreichen Sie uns"
                                                  description="Sie können bei uns kurzfristig sowohl per Email als auch telefonisch buchen. Sie können bei uns auch Gruppen von 50 oder mehr Personen unterbringen.
                                                            Wir sind jederzeit mobil erreichbar unter 0160 / 9660 4793. Oder per Email jochen-laessig@t-online.de"
                                                  icon={<TelephoneInbound/>}/>
                                </Link>
                            </Col>

                        </Row>
                    </Container>

                </Container>
        )
    }
}

export default MarketingArea
