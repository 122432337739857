import React, {Component} from 'react';

class PartnerLogo extends Component {
    render() {
        return (
            <a href={this.props.href} className="hvr-grow">
                <img
                    src={this.props.src}
                    alt={this.props.alt}>
                </img>
            </a>
        );
    }
}

export default PartnerLogo;
