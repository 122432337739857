import React from "react"
import "./legalStandard.css"

const AGB = () =>{

    return (
        <div className="legalStandard">
            AGB
        </div>
    )
}


export default AGB
