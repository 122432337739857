import React, {useState, useEffect} from "react"
import NavBar from '../Nav/Navbar'
import Banner from '../Banner/Banner'
import Dropdown from "../Dropdown/Dropdown"
import NumberDisplay from "./NumberDisplay"

const Header = () => {
    const [isOpen, setisOpen] = useState(false)

    const toggle = () => {
        setisOpen(!isOpen)
    }

    useEffect(() => {
        const hideMenu = () => {
            if(window.innerWidth > 768 && isOpen){
                setisOpen(false)
            }
        }

        window.addEventListener("resize", hideMenu)
        return () => {
        window.removeEventListener("resize", hideMenu)
        }
    })

    return(
        <React.Fragment>
            <NumberDisplay />
            <NavBar toggle={toggle}/>
            <Dropdown isOpen={isOpen} toggle={toggle}/>
            <Banner/>
        </React.Fragment>
    )
}

export default Header
