import React from 'react'
import { Container, Row} from 'react-bootstrap'
import MarketingArea from '../MarketingArea/MarketingArea'
import ApartmentList from '../ApartmentList/ApartmentList'
import SelectBox from '../../Layout/SelectBox/SelectBox'

class Home extends React.Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.filterData = this.filterData.bind(this)
    }

    handleChange(event) {
        this.props.handleChange(event)
    }

    filterData(event){
        this.props.filterData(event)
    }

    render() {
    return (
        <Container fluid>
            <Row>
                <SelectBox
                    state={this.props.state}
                    handleChange={this.handleChange}
                />
            </Row>
            <Row>
                <MarketingArea />
                <ApartmentList
                    state={this.props.state}
                    handleChange={this.handleChange}
                    filterData={this.filterData}
                    size={window.innerWidth > 1920 ? 6 : 3}
                />
            </Row>
        </Container>

    )
    }
}

export default Home
