import React from "react"

import {Form, Button, Row, Col, Modal} from 'react-bootstrap'

import "./contact.css"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name : "",
      email : "",
      subject : "",
      message : "",
      showModal : false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    const mailJochen =
        "https://us-central1-webpage-jochen.cloudfunctions.net/sendMailJochen?email=" + this.state.email +
        "&name=" + this.state.name +
        "&subject=" + this.state.subject +
        "&message=" + this.state.message +
        "&telefon" + this.state.telefon +
        "&stadtteil=" + this.props.state.stadtteil +
        "&personenAnzahl" + this.props.state.personenAnzahl +
        "&anreise" + this.props.state.anreise +
        "&abreise" + this.props.state.abreise +
        "&zimmerAnzahl" + this.props.state.zimmerAnzahl;

    const mailCustomer = "https://us-central1-webpage-jochen.cloudfunctions.net/sendMailCustomer?email=" + this.state.email + "&name=" + this.state.name + "&subject=" + this.state.subject
    fetch(mailJochen)
    fetch(mailCustomer)
    this.setState({
      showModal : true
    })
  }

  handleClose(){
    this.setState({
      showModal : false,
      name : "",
      email : "",
      telefon: "",
      subject : "",
      message : ""
    })
  }

  render() {
    return (
      <div>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Ihre Nachricht wurde erfolgreich gesendet.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen
              um das passende Apartment für Sie zu finden!
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>

        <h2 className="text-center" >Kontaktformular</h2>
        <div className="margin_small"></div>

        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md>
              <Form.Group>
                <Form.Control
                    required
                    type="text"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={event => this.setState({name : event.target.value})} />
              </Form.Group>

              <Form.Group>
                <Form.Control
                    required
                    type="email"
                    placeholder="E-Mail"
                    value={this.state.email}
                    onChange={event => this.setState({email : event.target.value})} />
              </Form.Group>

              <Form.Group>
                <Form.Control required type="text" placeholder="Telefonnummer" value={this.state.telefon}
                              onChange={event => this.setState({telefon : event.target.value})} />
              </Form.Group>

              <Form.Group>
                <Form.Control required type="text" placeholder="Betreff" value={this.state.subject}
                      onChange={event => this.setState({subject : event.target.value})} />
              </Form.Group>
            </Col>


            <Col md>
              <Form.Group>
                <Form.Control
                    className="textArea_contact"
                    required
                    rows="10"
                    type="text"
                    as="textarea"
                    placeholder="Nachricht"
                    value={this.state.message}
                    onChange={event => this.setState({message : event.target.value})} />
              </Form.Group>

              <Form.Group>
              <Button variant="primary" type="submit">Nachricht senden</Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default ContactForm
