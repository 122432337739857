import React from "react"

import {Col, Row} from "react-bootstrap"

import "./contact.css"

const ContactJochen = () => {
  return (
      <div>
        <h2 className="text-center" >So können Sie uns kontaktieren!</h2>
        <div className="margin_small"></div>
        <Row>
          <Col md>
            <p  className="text-left">Arthur-Hoffmann-Straße 13 | 04107 Leipzig</p>
          </Col>
          <Col md>
            <p  className="text-left">Kommen Sie doch gerne vorbei.</p>
          </Col>
        </Row>

        <Row>
          <Col md>
          <p  className="text-left">+49 160 9660 4793 | Mo-Fr 09:00-18:00 uhr</p>
          </Col>
          <Col md>
          <p  className="text-left" >Rufen Sie uns einfach an.</p>
          </Col>
        </Row>

        <Row>
          <Col md>
          <p  className="text-left" >jochen-laessig@t-online.de</p>
          </Col>
          <Col md>
          <p  className="text-left" >Schildern Sie Ihr Anliegen in einer E-Mail.</p>
          </Col>
        </Row>







      </div>
  )
}


export default ContactJochen
