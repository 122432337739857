import React, {useEffect, useState} from 'react';

export const LeipzigMap = (props) => {
    const [viewBox, setViewBox] = useState("0 70 1197 2190");

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth < 768;
            if (isMobile) {
                setViewBox("0 70 1197 1200");
            } else {
                setViewBox("0 70 1197 2190");
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initialer Aufruf für die erste Renderung

        const map = document.getElementById("map_svg");
        const mapElements = map.getElementsByClassName("map_design");
        for (let i = 0; i < mapElements.length; i++) {
            mapElements[i].style.fill = mainColor;
        }

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const mainColor = "#7a7a7a";

    const handleElementClick = (event) => {
        console.log("Element clicked:", event.target.id);
        props.handlechange(event);
    };

    const handleElementHover = (event) => {
        event.target.style.fill = "#cbcbcb";
        event.target.style.cursor = "pointer";
    };

    const handleElementLeave = (event) => {
        event.target.style.fill = mainColor;
        event.target.style.cursor = "default";
    };

    return (
        <svg
            viewBox={viewBox}
            height="auto"
            width="100%"
            id="map_svg"
            version="1.1">
            <defs
                id="defs837">
                <marker
                    style={{overflow: "visible"}}
                    id="Arrow1Lstart"
                    refX="0.0"
                    refY="0.0"
                    orient="auto">
                    <path
                        transform="scale(0.8) translate(12.5,0)"
                        style={{
                            fillRule:"evenodd",
                            stroke:mainColor,
                            strokeWidth:"1pt",
                            strokeOpacity:"1",
                            fill:mainColor,
                            fillOpacity:"0.4"}}
                        d="M 0.0,0.0 L 5.0,-5.0 L -12.5,0.0 L 5.0,5.0 L 0.0,0.0 z "
                        id="path2836"/>
                </marker>
                <font
                    id="font2826"
                    horizAdvX="1024">
                    <font-face
                        fontFamily="SVGFont 1"
                        id="font-face2828"
                        unitsPerEm="1024"/>
                    <missing-glyph
                        id="missing-glyph2830"
                        d="M0,0h1000v1024h-1000z"/>
                </font>
            </defs>

            <path
                id="map_westen"
                d="m 156.99969,1174.2392 221.41693,-328.5581 -25.6545,-6.61446 18.86652,-15.47505 -27.96092,-24.93546 135.03322,-94.82356 -17.1811,-28.43768 -31.99239,-93.60736 -37.9169,-26.66033 -2.96226,-8.29432 55.69045,-14.81129 L 465.667,523.72727 354.28609,391.01809 251.1995,369.68983 238.16557,252.38441 l -61.61498,-16.58865 -17.77355,40.28671 27.25278,4.73962 2.36981,15.40374 -30.80749,-2.36981 2.36981,20.14336 7.10942,18.95845 23.69806,-1.1849 18.95846,20.14336 -22.51317,35.54709 -17.77355,20.14336 9.47923,26.06787 -5.92452,11.84904 -10.66413,-9.47923 -13.03393,23.69807 3.55471,4.73961 -39.10181,-20.14336 -4.73961,8.29433 20.14335,17.77355 15.40374,11.84903 -13.03393,9.47923 -18.95845,-16.58865 -11.84904,8.29432 14.21884,1.18491 9.47923,10.66413 -2.36981,98.34697 23.69807,52.13575 28.43768,30.80748 -1.18491,20.14336 -34.36219,7.10942 -1.18491,10.66413 -7.10942,-1.1849 -4.73961,-20.14336 -7.10942,3.55471 7.10942,27.25278 -22.51316,1.1849 10.66413,29.62258 -11.84903,3.55471 2.3698,15.40375 14.21884,-5.92452 -8.29432,13.03394 16.58864,14.21884 9.47923,27.25277 -9.47923,-1.1849 8.29433,21.32826 8.29432,-3.55471 4.73961,26.06787 -4.73961,14.21884 42.65652,-7.10942 3.55471,50.95084 30.80749,10.66413 30.80748,31.99239 -60.43007,-1.1849 -1.1849,-22.51316 -43.84142,-5.92452 -2.36981,17.77355 11.84903,4.73961 2.36981,13.03394 -22.51316,7.10942 20.14335,54.50556 -17.77355,14.2188 4.73962,5.9245 8.29432,-2.3698 26.06787,68.7244 -11.84903,4.7396 4.73961,5.9246 -16.58864,2.3698 8.29432,21.3282 -16.58865,1.1849 z"
                className="map_design"
                onClick={handleElementClick}
                onMouseOver={handleElementHover}
                onMouseOut={handleElementLeave}
            />
            <path
                id="map_sued"
                d="m 628.38988,682.85033 11.72994,16.75707 v 25.13559 h 10.05424 l 24.29774,37.7034 -36.86554,45.24407 h -19.27062 l -16.75706,-7.54068 -20.94633,-56.13616 -46.91978,-0.83786 -17.59492,-12.5678 -5.86497,5.02712 -15.08136,0.83786 -14.14186,-25.68594 -123.98685,88.17458 26.0459,26.97053 -15.26044,9.18466 20.26857,7.96863 -88.39051,134.55272 -137.16251,204.57268 -10.05424,3.3514 4.18926,9.2164 101.38024,-27.6491 102.21808,-1.6757 2.51356,-30.1628 -10.89209,-12.5678 20.94633,-36.8655 13.40565,1.6757 5.02712,-63.6768 25.97345,-8.3786 2.51356,13.4057 15.91921,4.1893 26.8113,-4.1893 -4.18927,-9.2164 -12.56779,-4.1893 17.59491,-12.5678 18.43277,3.3515 -2.51356,-10.05428 -10.05423,-6.70283 13.40565,-25.97344 8.37853,5.02712 2.51356,-19.27063 -12.5678,-4.18926 -0.83785,-20.94633 12.56779,-7.54068 v -6.70283 l -13.40565,-4.18926 0.83786,-15.08136 13.40565,1.67571 25.13559,20.94633 -3.35141,19.27062 20.10848,6.70282 17.59491,-36.86554 42.73051,62.00114 h 17.59492 l -0.83785,9.21638 14.2435,-0.83785 v -18.43277 h 47.75763 l -1.6757,15.08136 9.21638,-0.83785 0.83785,10.89209 13.40565,-0.83786 -0.83785,-24.29774 6.70283,-0.83785 v -10.89209 l 44.40622,-1.67571 51.94689,10.05424 15.08136,47.75763 -7.54068,28.48702 72.89323,36.8655 24.29774,-6.7028 -2.51356,26.8113 38.54124,2.5136 17.59492,-17.595 -10.89209,-15.9192 -9.21639,11.73 -8.37853,-3.3514 -5.02712,-15.0814 30.16272,-77.92033 z"
                className="map_design"
                onClick={handleElementClick}
                onMouseOver={handleElementHover}
                onMouseOut={handleElementLeave}
            />
            <path
                id="map_ost"
                d="m 619.23726,582.51578 41.37039,-59.7687 -38.77058,-132.52339 6.676,-15.51186 6.70462,1.03573 1.66593,-5.6584 70.59844,5.3239 9.17179,-25.77988 -24.97174,-26.34922 11.92163,-10.28434 4.40634,4.39111 34.32728,-14.30291 3.36372,7.11594 20.72015,-9.67115 3.34741,-2.3099 -4.63531,-15.70177 4.18781,-0.8451 10.27311,5.42829 54.25161,0.32507 10.27636,7.31345 14.27758,19.66486 14.87292,0.60269 1.47718,6.28131 -10.04813,3.5783 6.13933,37.48334 -9.83244,7.13878 -4.81943,-1.039 -8.98232,14.25911 7.95887,-0.43271 5.30763,41.04567 31.8427,2.45849 -7.70952,23.47324 -0.18446,14.45331 -11.06965,18.45195 4.19833,5.22933 -9.62664,5.0438 5.87801,7.53052 46.35982,39.5084 49.2241,0.12432 33.8471,71.36852 60.9335,-11.83539 -4.9833,25.35374 5.2374,0.40983 2.1127,10.46956 14.2558,7.09707 14.0245,-5.47031 6.9243,6.90037 -8.5764,6.71764 5.0333,3.55217 -1.663,7.33411 6.4944,0.61718 -1.0317,9.00869 -39.337,24.36577 50.1215,34.47484 c 0,0 -27.7901,39.63666 -28.6276,39.84761 -0.8375,0.21086 -6.2915,-4.38786 -6.2915,-4.38786 l -3.5558,2.93868 30.8756,48.75154 -3.5424,10.68871 -63.2415,9.53531 -19.6939,-2.47948 -14.0224,6.72709 -12.782,-2.70097 -0.8291,5.02862 2.1309,20.94262 -16.2457,53.44142 -12.3576,0.4402 13.0374,29.30237 -9.4233,1.4826 0.6368,4.8166 -14.8694,1.492 0.01,4.8175 -329.15253,-291.42277 0.14061,-39.79832 44.57611,-22.90866 -1.91234,-15.70646 z"
                className="map_design"
                onClick={handleElementClick}
                onMouseOver={handleElementHover}
                onMouseOut={handleElementLeave}
            />
            <path
                id="map_nord"
                d="m 251.1995,250.60705 12.44149,109.01111 90.6451,20.14335 11.84904,7.70187 112.69796,138.70841 -22.62204,10.47317 18.01384,21.78418 33.09521,-4.60819 17.17599,16.33813 8.37853,-0.41892 24.29774,-29.74379 15.50028,-3.77034 18.43277,12.5678 12.14887,32.67627 4.18927,2.51356 41.89266,-61.58221 -35.18984,-131.96187 10.05424,-23.45989 6.70283,1.67571 1.25678,-5.02712 69.12288,5.02712 5.44605,-17.17599 -24.29774,-28.48701 22.62203,-18.8517 5.02712,5.44605 35.60876,-15.08136 3.77034,8.79746 12.5678,-5.86497 -4.18927,-19.27063 12.5678,-1.6757 9.21639,5.02712 49.85226,0.83785 0.41893,-23.45989 5.86497,-23.04096 6.70282,0.83785 1.67571,-10.05424 43.98729,3.35142 -1.25678,12.56779 28.06808,-0.83785 0.41893,4.18927 13.40565,-0.41893 v -5.44605 l 6.70283,0.41893 0.83785,-14.2435 h 7.9596 l -0.83785,-3.77034 4.60819,-2.09464 -1.6757,-5.02712 4.60819,-0.83785 -7.12175,-28.48701 6.70282,-0.83785 -2.09463,-20.5274 -71.21752,-3.77034 v -26.39238 l -7.95961,-0.83785 4.6082,-28.06808 -59.9065,-2.51356 -1.25678,32.67627 h -38.12232 l 2.51356,38.12232 -8.79746,3.35142 h -17.17599 l -20.10848,13.82457 -8.37853,-2.09463 -3.77034,4.18927 -4.18927,-0.41893 -2.93248,-5.44605 -14.24351,-0.41892 -6.28389,-7.95961 -36.02769,2.09463 -18.01384,43.9873 -2.51356,22.20311 -6.70283,3.77033 -7.12175,-12.56779 -62.83899,-27.64916 -6.2839,-9.21638 -2.09463,8.79746 -10.05424,1.25678 0.83786,15.91921 h 4.60819 l -1.67571,18.43277 -28.06808,-3.35142 0.83785,10.47317 -7.9596,-1.25678 1.6757,-11.31102 -88.3935,-2.93249 0.83785,-8.37853 -13.40565,3.35141 -19.68955,-1.25677 -15.91921,4.60819 -16.75707,-0.83786 -8.37853,3.77034 2.93249,16.75707 z"
                className="map_design"
                onClick={handleElementClick}
                onMouseOver={handleElementHover}
                onMouseOut={handleElementLeave}
            />
            <path
                id="map_zentrum"
                d="m 401.08977,552.16495 33.1773,24.29051 37.32445,100.71679 26.06787,49.76594 h 7.70187 l 7.70188,-8.88678 4.73961,-1.1849 15.40374,15.99619 h 53.32065 l 23.10562,61.61497 8.29432,3.55471 17.1811,-0.59245 27.84523,-34.95465 -15.9962,-26.66032 h -13.62639 l -1.1849,-29.62258 -13.62639,-23.69807 -0.59245,-58.65271 43.84142,-20.14336 -0.59245,-7.10942 -63.39233,-8.29432 -11.84903,-34.3622 -14.21884,-10.66413 -12.44148,2.36981 -24.88297,31.39994 h -12.44149 l -20.73581,-15.9962 -30.21503,4.14716 -23.10562,-25.47542 z"
                className="map_design"
                onClick={handleElementClick}
                onMouseOver={handleElementHover}
                onMouseOut={handleElementLeave}
            />
            <g
                style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '40px',
                        lineHeight: 1.25,
                        fontFamily: 'sans-serif',
                        display: 'inline',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        stroke: 'none'
                }}

                id="westen"
                aria-label="WESTEN">
                <path
                    id="westen_w"
                    style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '40px', lineHeight: 1.25, fontFamily: 'sans-serif', display: 'inline', fill: '#ffffff', fillOpacity: 1, stroke: 'none' }}
                    d="m 250.29297,654.71527 -9.07031,34.89844 h -5.22657 l -7.33593,-28.96875 -7.17188,28.96875 h -5.10937 l -9.23438,-34.89844 h 4.75781 l 7.33594,29.01563 7.21875,-29.01563 h 4.71094 l 7.28906,29.29688 7.28906,-29.29688 z"/>
                <path
                    id="westen_e1"
                    style={{ fontSize: '48px', fill: '#ffffff', fillOpacity: 1 }}
                    d="m 280.10547,689.61371 h -22.99219 v -34.89844 h 22.99219 v 4.125 h -18.35156 v 9.5625 h 18.35156 v 4.125 h -18.35156 v 12.96094 h 18.35156 z"/>
                <path
                    id="westen_s"
                    style={{ fontSize: '48px', fill: '#ffffff', fillOpacity: 1 }}
                    d="m 312.84766,679.65277 q 0,2.03906 -0.96094,4.03125 -0.9375,1.99219 -2.64844,3.375 -1.875,1.5 -4.38281,2.34375 -2.48438,0.84375 -6,0.84375 -3.77344,0 -6.79688,-0.70312 -3,-0.70313 -6.11718,-2.08594 v -5.8125 h 0.32812 q 2.64844,2.20312 6.11719,3.39844 3.46875,1.19531 6.51562,1.19531 4.3125,0 6.70313,-1.61719 2.41406,-1.61719 2.41406,-4.3125 0,-2.32031 -1.14844,-3.42187 -1.125,-1.10157 -3.44531,-1.71094 -1.75781,-0.46875 -3.82031,-0.77344 -2.03906,-0.30469 -4.33594,-0.77344 -4.64062,-0.98437 -6.89062,-3.35156 -2.22657,-2.39062 -2.22657,-6.21094 0,-4.38281 3.70313,-7.17187 3.70312,-2.8125 9.39844,-2.8125 3.67968,0 6.75,0.70312 3.07031,0.70313 5.4375,1.73438 v 5.48437 h -0.32813 q -1.99219,-1.6875 -5.25,-2.78906 -3.23437,-1.125 -6.63281,-1.125 -3.72656,0 -6,1.54688 -2.25,1.54687 -2.25,3.98437 0,2.17969 1.125,3.42188 1.125,1.24218 3.96094,1.89843 1.5,0.32813 4.26562,0.79688 2.76563,0.46875 4.6875,0.96094 3.89063,1.03125 5.85938,3.11718 1.96875,2.08594 1.96875,5.83594 z"/>
                <path
                    id="westen_t"
                    style={{ fontSize: '48px', fill: '#ffffff', fillOpacity: 1 }}

                    d="m 345.19141,658.84027 h -12.46875 v 30.77344 h -4.64063 v -30.77344 h -12.46875 v -4.125 h 29.57813 z"/>
                <path
                    id="westen_e2"
                    style={{ fontSize: '48px', fill: '#ffffff', fillOpacity: 1 }}

                    d="m 372.91797,689.61371 h -22.99219 v -34.89844 h 22.99219 v 4.125 h -18.35156 v 9.5625 h 18.35156 v 4.125 h -18.35156 v 12.96094 h 18.35156 z"/>
                <path
                    id="westen_n"
                    style={{ fontSize: '48px', fill: '#ffffff', fillOpacity: 1 }}

                    d="m 406.92578,689.61371 h -5.74219 l -16.54687,-31.21875 v 31.21875 h -4.33594 v -34.89844 h 7.19531 l 15.09375,28.5 v -28.5 h 4.33594 z"/>
            </g>
            <g
                style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '40px',
                    lineHeight: 1.25,
                    fontFamily: 'sans-serif',
                    display: 'inline',
                    fill: '#ffffff',
                    fillOpacity: 1,
                    stroke: 'none'
                }}

                id="sued"
                aria-label="Süd">
                <path
                    id="sued_S"
                    style={{
                        fontSize: '74.6667px',
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}

                    d="m 574.77,862.88759 q 0,3.17187 -1.49479,6.27083 -1.45834,3.09896 -4.11979,5.25001 -2.91667,2.33333 -6.81772,3.64583 -3.86458,1.3125 -9.33333,1.3125 -5.8698,0 -10.57293,-1.09375 -4.66666,-1.09375 -9.51563,-3.24479 v -9.04167 h 0.51042 q 4.1198,3.42708 9.51563,5.28646 5.39584,1.85937 10.13542,1.85937 6.70834,0 10.42709,-2.51562 3.75521,-2.51563 3.75521,-6.70834 0,-3.60938 -1.78646,-5.32292 -1.75,-1.71354 -5.35938,-2.66146 -2.73437,-0.72917 -5.94271,-1.20312 -3.17187,-0.47396 -6.74479,-1.20313 -7.21876,-1.53125 -10.71876,-5.21354 -3.46354,-3.71876 -3.46354,-9.66147 0,-6.81771 5.76042,-11.15625 5.76042,-4.375 14.6198,-4.375 5.72396,0 10.5,1.09375 4.77605,1.09375 8.45834,2.69791 v 8.53126 h -0.51042 q -3.09896,-2.625 -8.16667,-4.33855 -5.03125,-1.75 -10.31771,-1.75 -5.79688,0 -9.33334,2.40625 -3.5,2.40626 -3.5,6.19792 0,3.39063 1.75,5.32292 1.75,1.9323 6.16146,2.95313 2.33334,0.51042 6.63542,1.23958 4.30209,0.72917 7.29167,1.4948 6.05209,1.60416 9.11459,4.84896 3.0625,3.24479 3.0625,9.07813 z"/>
                <path
                    id="sued_ue"
                    style={{
                        fontSize: '74.6667px',
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}

                    d="m 619.57731,878.38239 h -6.85417 v -4.52084 q -3.46354,2.73438 -6.63542,4.19271 -3.17187,1.45833 -7,1.45833 -6.41667,0 -9.98959,-3.90104 -3.57292,-3.9375 -3.57292,-11.52084 v -26.4323 h 6.85417 v 23.18751 q 0,3.09896 0.29167,5.32292 0.29167,2.1875 1.23959,3.75521 0.98437,1.60417 2.55208,2.33333 1.56771,0.72917 4.55729,0.72917 2.66146,0 5.79688,-1.38542 3.17188,-1.38542 5.90625,-3.53646 v -30.40626 h 6.85417 z m -5.6875,-47.54169 h -7.25521 v -7.10938 h 7.25521 z m -15.0573,0 h -7.25521 v -7.10938 h 7.25521 z"/>
                <path
                    id="sued_d"
                    style={{
                        fontSize: '74.6667px',
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}

                    d="M 666.09817,878.38239 H 659.244 v -4.26563 q -2.95312,2.55208 -6.16146,3.97396 -3.20833,1.42187 -6.96354,1.42187 -7.29167,0 -11.59376,-5.61458 -4.26563,-5.61459 -4.26563,-15.56772 0,-5.17708 1.45834,-9.22396 1.49479,-4.04688 4.01042,-6.89063 2.47917,-2.77083 5.76042,-4.22917 3.31771,-1.45833 6.85417,-1.45833 3.20833,0 5.6875,0.69271 2.47917,0.65625 5.21354,2.07812 v -17.64584 h 6.85417 z M 659.244,868.35634 v -23.3698 q -2.77083,-1.23959 -4.95833,-1.71355 -2.1875,-0.47396 -4.77605,-0.47396 -5.76042,0 -8.96875,4.01042 -3.20834,4.01042 -3.20834,11.37501 0,7.25521 2.47917,11.04688 2.47917,3.75521 7.94792,3.75521 2.91667,0 5.90626,-1.27604 2.98958,-1.3125 5.57812,-3.35417 z"/>
            </g>
            <g
                style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '96px',
                    lineHeight: 1.25,
                    fontFamily: 'sans-serif',
                    whiteSpace: 'pre',
                    inlineSize: '268.445',
                    display: 'inline',
                    fill: '#fefefe',
                    fillOpacity: 1,
                    stroke: 'none'
                }}
                id="ost"
                transform="translate(-9.4792265,30.807486)"
                aria-label="OST">
                <path
                    id="ost_o"
                    style={{
                        fontSize: '96px',
                        fill: '#fefefe',
                        fillOpacity: 1
                    }}

                    d="m 838.70312,589.91559 q 4.26563,4.6875 6.51563,11.48437 2.29687,6.79688 2.29687,15.42188 0,8.625 -2.34375,15.46875 -2.29687,6.79687 -6.46875,11.34375 -4.3125,4.73437 -10.21875,7.125 -5.85937,2.39062 -13.40625,2.39062 -7.35937,0 -13.40625,-2.4375 -6,-2.4375 -10.21875,-7.07812 -4.21875,-4.64063 -6.51562,-11.39063 -2.25,-6.75 -2.25,-15.42187 0,-8.53125 2.25,-15.28125 2.25,-6.79688 6.5625,-11.625 4.125,-4.59375 10.21875,-7.03125 6.14062,-2.4375 13.35937,-2.4375 7.5,0 13.45313,2.48437 6,2.4375 10.17187,6.98438 z m -0.84375,26.90625 q 0,-13.59375 -6.09375,-20.95313 -6.09375,-7.40625 -16.64062,-7.40625 -10.64063,0 -16.73438,7.40625 -6.04687,7.35938 -6.04687,20.95313 0,13.73437 6.1875,21.04687 6.1875,7.26563 16.59375,7.26563 10.40625,0 16.54687,-7.26563 6.1875,-7.3125 6.1875,-21.04687 z"/>
                <path
                    id="ost_s"
                    style={{
                        fontSize: '96px',
                        fill: '#fefefe',
                        fillOpacity: 1
                    }}
                    d="m 912.95312,631.77496 q 0,4.07813 -1.92187,8.0625 -1.875,3.98438 -5.29688,6.75 -3.75,3 -8.76562,4.6875 -4.96875,1.6875 -12,1.6875 -7.54688,0 -13.59375,-1.40625 -6,-1.40625 -12.23438,-4.17187 v -11.625 h 0.65625 q 5.29688,4.40625 12.23438,6.79687 6.9375,2.39063 13.03125,2.39063 8.625,0 13.40625,-3.23438 4.82812,-3.23437 4.82812,-8.625 0,-4.64062 -2.29687,-6.84375 -2.25,-2.20312 -6.89063,-3.42187 -3.51562,-0.9375 -7.64062,-1.54688 -4.07813,-0.60937 -8.67188,-1.54687 -9.28125,-1.96875 -13.78125,-6.70313 -4.45312,-4.78125 -4.45312,-12.42187 0,-8.76563 7.40625,-14.34375 7.40625,-5.625 18.79687,-5.625 7.35938,0 13.5,1.40625 6.14063,1.40625 10.875,3.46875 v 10.96875 h -0.65625 q -3.98437,-3.375 -10.5,-5.57813 -6.46875,-2.25 -13.26562,-2.25 -7.45313,0 -12,3.09375 -4.5,3.09375 -4.5,7.96875 0,4.35938 2.25,6.84375 2.25,2.48438 7.92187,3.79688 3,0.65625 8.53125,1.59375 5.53125,0.9375 9.375,1.92187 7.78125,2.0625 11.71875,6.23438 3.9375,4.17187 3.9375,11.67187 z"/>
                <path
                    id="ost_t"
                    style={{
                        fontSize: '96px',
                        fill: '#fefefe',
                        fillOpacity: 1
                    }}
                    d="m 977.64062,590.14996 h -24.9375 v 61.54688 h -9.28125 v -61.54688 h -24.9375 v -8.25 h 59.15625 z"/>
            </g>
            <g
                style={{
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontSize: '56.3088px',
                    lineHeight: 1.25,
                    fontFamily: 'sans-serif',
                    InkscapeFontSpecification: "'sans-serif Bold'",
                    letterSpacing: '4.44312px',
                    display: 'inline',
                    fill: '#ffffff',
                    fillOpacity: 1,
                    stroke: 'none',
                    strokeWidth: '0.879824'
                }}
                id="nord"
                transform="rotate(-32.22223)"
                aria-label="NORD">
                <path
                    id="nord_N"
                    style={{
                        strokeWidth: '0.879824',
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}

                    d="m 267.28533,614.8598 h -10.17298 l -17.37654,-28.09941 v 28.09941 h -9.67808 v -40.93935 h 12.61999 l 14.92953,23.45283 v -23.45283 h 9.67808 z"/>
                <path
                    id="nord_o"
                    style={{
                        strokeWidth: '0.879824',
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}

                    d="m 322.04344,594.40387 q 0,9.78805 -5.60889,15.56191 -5.60888,5.74635 -15.50691,5.74635 -9.87054,0 -15.47942,-5.74635 -5.60889,-5.77386 -5.60889,-15.56191 0,-9.87054 5.60889,-15.5894 5.60888,-5.74636 15.47942,-5.74636 9.84304,0 15.47942,5.74636 5.63638,5.71886 5.63638,15.5894 z m -13.99472,10.36544 q 1.5397,-1.86963 2.28205,-4.39913 0.74235,-2.55699 0.74235,-5.9938 0,-3.68427 -0.85233,-6.26876 -0.85233,-2.58448 -2.22706,-4.17917 -1.40222,-1.64967 -3.24435,-2.39202 -1.81464,-0.74235 -3.79425,-0.74235 -2.0071,0 -3.79424,0.71486 -1.75965,0.71485 -3.24436,2.36453 -1.37472,1.53969 -2.25455,4.26165 -0.85233,2.69446 -0.85233,6.26875 0,3.65677 0.82484,6.24126 0.85233,2.55699 2.22705,4.17917 1.37473,1.62218 3.21686,2.39202 1.84214,0.76985 3.87673,0.76985 2.0346,0 3.87673,-0.76985 1.84214,-0.79734 3.21686,-2.44701 z"/>
                <path
                    id="nord_r"
                    style={{
                        strokeWidth: '0.879824',
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}

                    d="m 357.47289,586.45795 q 0,-1.53969 -0.63237,-2.63947 -0.63237,-1.09978 -2.17207,-1.73216 -1.07228,-0.43991 -2.502,-0.52239 -1.42972,-0.10998 -3.32684,-0.10998 h -3.82174 v 11.0253 h 3.24436 q 2.52949,0 4.23415,-0.24745 1.70467,-0.24745 2.85944,-1.12727 1.09978,-0.85233 1.59468,-1.86963 0.52239,-1.04479 0.52239,-2.77695 z m 16.46923,28.40185 H 361.04718 L 349.8844,599.84779 h -4.86653 v 15.01201 h -10.50291 v -40.93935 h 17.70648 q 3.62928,0 6.24126,0.41241 2.61198,0.41242 4.89402,1.78715 2.30955,1.37472 3.65678,3.57429 1.37472,2.17206 1.37472,5.47141 0,4.5366 -2.11708,7.39603 -2.08958,2.85943 -5.9938,4.75655 z"/>
                <path
                    id="nord_d"
                    style={{
                        strokeWidth: '0.879824',
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}

                    d="m 421.74411,594.43137 q 0,5.71886 -2.61198,10.25546 -2.61198,4.5091 -6.59869,6.92862 -2.9969,1.81464 -6.57119,2.52949 -3.57429,0.71486 -8.46832,0.71486 H 383.0593 v -40.93935 h 14.84705 q 5.004,0 8.63328,0.85233 3.62928,0.82483 6.10379,2.36453 4.23416,2.58448 6.65368,6.98361 2.44701,4.37163 2.44701,10.31045 z m -10.91533,-0.0825 q 0,-4.04169 -1.4847,-6.90113 -1.45722,-2.88692 -4.64658,-4.5091 -1.62218,-0.79734 -3.32684,-1.07229 -1.67717,-0.30243 -5.08649,-0.30243 h -2.66697 v 25.6249 h 2.66697 q 3.76675,0 5.5264,-0.32994 1.75965,-0.35743 3.43682,-1.26474 2.88693,-1.64968 4.23416,-4.39913 1.34723,-2.77695 1.34723,-6.84614 z"/>
            </g>
            <g
                transform="rotate(44.140807,488.24432,650.25337)"
                style={{
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontSize: '40.8852px',
                    lineHeight: 1.25,
                    fontFamily: 'sans-serif',
                    InkscapeFontSpecification: "'sans-serif, Normal'",
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    display: 'inline',
                    opacity: 0.999,
                    fill: '#ffffff',
                    fillOpacity: 1,
                    stroke: 'none',
                    strokeWidth: '1.02047',
                    strokeMiterlimit: 4,
                    strokeDasharray: 'none'
                }}

                id="zentrum"
                aria-label="Zentrum">
                <path
                    id="zentrum_Z"
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontSize: '40.8852px',
                        fontFamily: 'sans-serif',
                        InkscapeFontSpecification: "'sans-serif, Normal'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        strokeWidth: '1.02047',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none'
                    }}

                    d="m 470.34012,626.53656 h -23.19756 v -3.67328 l 18.22666,-22.53876 h -17.5479 v -3.51358 h 22.07961 v 3.57347 l -18.40633,22.63858 h 18.84552 z"/>
                <path
                    id="zentrum_e"
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontSize: '40.8852px',
                        fontFamily: 'sans-serif',
                        InkscapeFontSpecification: "'sans-serif, Normal'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        strokeWidth: '1.02047',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none'
                    }}

                    d="m 493.81717,615.77625 h -16.42994 q 0,2.05623 0.61887,3.59342 0.61887,1.51723 1.6969,2.49544 1.0381,0.95824 2.4555,1.43737 1.43737,0.47912 3.15423,0.47912 2.27584,0 4.57164,-0.89836 2.31576,-0.91832 3.29397,-1.79671 h 0.19964 v 4.09251 q -1.89653,0.79854 -3.87292,1.33756 -1.97638,0.53901 -4.1524,0.53901 -5.54985,0 -8.66415,-2.99452 -3.1143,-3.01449 -3.1143,-8.54437 0,-5.46999 2.97456,-8.68411 2.99452,-3.21412 7.86561,-3.21412 4.51174,0 6.94729,2.63518 2.4555,2.63518 2.4555,7.4863 z m -3.65331,-2.87474 q -0.02,-2.9546 -1.49726,-4.57164 -1.45734,-1.61704 -4.45186,-1.61704 -3.01448,0 -4.8112,1.77675 -1.77675,1.77675 -2.01631,4.41193 z"/>
                <path
                    id="zentrum_n"
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontSize: '40.8852px',
                        fontFamily: 'sans-serif',
                        InkscapeFontSpecification: "'sans-serif, Normal'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        strokeWidth: '1.02047',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none'
                    }}
                    d="m 518.15265,626.53656 h -3.75313 v -12.69677 q 0,-1.53719 -0.17967,-2.87474 -0.17967,-1.35752 -0.6588,-2.11613 -0.49908,-0.83847 -1.43737,-1.23774 -0.93828,-0.41923 -2.43554,-0.41923 -1.53719,0 -3.21412,0.75861 -1.67693,0.75862 -3.21412,1.93646 v 16.64954 h -3.75313 v -22.2992 h 3.75313 v 2.47547 q 1.75679,-1.45734 3.63335,-2.27584 1.87657,-0.8185 3.85295,-0.8185 3.61339,0 5.50992,2.17602 1.89653,2.17602 1.89653,6.26853 z"/>
                <path
                    id="zentrum_t"
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontSize: '40.8852px',
                        fontFamily: 'sans-serif',
                        InkscapeFontSpecification: "'sans-serif, Normal'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        strokeWidth: '1.02047',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none'
                    }}
                    d="m 536.95825,626.33693 q -1.05807,0.27948 -2.31577,0.45916 -1.23773,0.17967 -2.21594,0.17967 -3.41376,0 -5.19051,-1.83664 -1.77675,-1.83664 -1.77675,-5.88923 v -11.8583 h -2.53536 v -3.15423 h 2.53536 v -6.40828 h 3.75314 v 6.40828 h 7.74583 v 3.15423 h -7.74583 V 617.553 q 0,1.75678 0.0799,2.75496 0.0799,0.97821 0.55898,1.83664 0.4392,0.79853 1.19781,1.17784 0.77857,0.35934 2.35569,0.35934 0.91832,0 1.91649,-0.25952 0.99818,-0.27949 1.43737,-0.45916 h 0.19964 z"/>
                <path
                    id="zentrum_r"
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontSize: '40.8852px',
                        fontFamily: 'sans-serif',
                        InkscapeFontSpecification: "'sans-serif, Normal'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        strokeWidth: '1.02047',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none'
                    }}
                    d="m 555.42447,608.32987 h -0.19964 q -0.83846,-0.19964 -1.637,-0.27949 -0.77858,-0.0998 -1.85661,-0.0998 -1.73682,0 -3.35386,0.77858 -1.61704,0.75861 -3.1143,1.97638 v 15.83104 h -3.75314 v -22.2992 h 3.75314 v 3.29397 q 2.23591,-1.79671 3.9328,-2.53536 1.71686,-0.75861 3.49361,-0.75861 0.97821,0 1.41741,0.0599 0.43919,0.0399 1.31759,0.17967 z"/>
                <path
                    id="zentrum_u"
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontSize: '40.8852px',
                        fontFamily: 'sans-serif',
                        InkscapeFontSpecification: "'sans-serif, Normal'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        strokeWidth: '1.02047',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none'
                    }}
                    d="m 577.48411,626.53656 h -3.75313 v -2.47547 q -1.89653,1.49726 -3.63336,2.2958 -1.73682,0.79854 -3.83298,0.79854 -3.51358,0 -5.47,-2.13609 -1.95642,-2.15606 -1.95642,-6.30846 v -14.47352 h 3.75314 v 12.69677 q 0,1.69689 0.1597,2.91467 0.15971,1.1978 0.67876,2.05623 0.53902,0.8784 1.39745,1.27767 0.85843,0.39927 2.49543,0.39927 1.45733,0 3.17419,-0.75862 1.73683,-0.75861 3.23409,-1.93645 v -16.64954 h 3.75313 z"/>
                <path
                    id="zentrum_m"
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontSize: '40.8852px',
                        fontFamily: 'sans-serif',
                        InkscapeFontSpecification: "'sans-serif, Normal'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        fill: '#ffffff',
                        fillOpacity: 1,
                        strokeWidth: '1.02047',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none'
                    }}
                    d="m 617.41106,626.53656 h -3.75313 v -12.69677 q 0,-1.43737 -0.13974,-2.77492 -0.11979,-1.33756 -0.53902,-2.1361 -0.45916,-0.85843 -1.31759,-1.29762 -0.85843,-0.4392 -2.47547,-0.4392 -1.57711,0 -3.15423,0.79854 -1.57711,0.77858 -3.15423,1.99635 0.0599,0.45916 0.0998,1.07803 0.0399,0.5989 0.0399,1.1978 v 14.27389 h -3.75314 v -12.69677 q 0,-1.4773 -0.13974,-2.79489 -0.11978,-1.33755 -0.53902,-2.13609 -0.45916,-0.85843 -1.31758,-1.27766 -0.85843,-0.4392 -2.47548,-0.4392 -1.53718,0 -3.09433,0.75861 -1.53719,0.75862 -3.07438,1.93646 v 16.64954 h -3.75313 v -22.2992 h 3.75313 v 2.47547 q 1.75679,-1.45734 3.49361,-2.27584 1.75678,-0.8185 3.73317,-0.8185 2.27583,0 3.85295,0.95825 1.59708,0.95824 2.37565,2.65514 2.27584,-1.9165 4.15241,-2.75496 1.87656,-0.85843 4.01266,-0.85843 3.67327,0 5.4101,2.23591 1.75678,2.21594 1.75678,6.20864 z"/>
            </g>

        </svg>
    )
}
