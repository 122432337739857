import React from 'react'
import { Route, Switch } from "react-router-dom";
import Home from "./Home/Home";
import AboutUs from "./AboutUs/AboutUs";
import Contact from "./Contact/Contact";
import Booking from "./Booking/Booking";
import Withdrawal from "../Layout/Legal/Withdrawal";
import Privacy from "../Layout/Legal/Privacy";
import Impressum from "../Layout/Legal/Impressum";
import AGB from "../Layout/Legal/AGB";
import ApartmentPage from "./ApartmentPage/ApartmentPage";

class Body extends React.Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.filterData = this.filterData.bind(this)
    }

    handleChange(event) {
        this.props.handleChange(event)
    }

    filterData(event){
        this.props.filterData(event)
    }

    render(){
        return (
            <Switch>
                <Route exact path="/" >
                    <Home
                        state={this.props.state}
                        handleChange={this.handleChange}
                        filterData={this.filterData}
                    />
                </Route >
                <Route path="/booking">
                    <Booking
                        state={this.props.state}
                        handleChange={this.handleChange}
                        filterData={this.filterData}
                    />
                </Route >
                <Route path="/apartment/:id" render={(matchProps) =>
                    <ApartmentPage
                        {...matchProps}
                        {...this.props.state}
                        handleChange={this.handleChange}
                    />} />
                <Route path="/aboutUs"><AboutUs/></Route >
                <Route path="/contact"><Contact state={this.props.state}/></Route >
                <Route path="/agb"><AGB/></Route >
                <Route path="/privacy"><Privacy/></Route >
                <Route path="/impressum"><Impressum/></Route >
                <Route path="/withdrawal"><Withdrawal/></Route >
            </Switch>
        )
    }

}

export default Body
