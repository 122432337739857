import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ApartmentList from '../ApartmentList/ApartmentList'
import {LeipzigMap} from "./LeipzigMap";

class Booking extends React.Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.filterData = this.filterData.bind(this)
    }

    handleChange(event) {
        this.props.handleChange(event)
    }

    filterData(event){
        this.props.filterData(event)
    }

    render() {
        return (
            <Container fluid>
                <Row className="d-flex flex-column-reverse flex-md-row">
                    <Col md={6} className="mb-3 mb-md-0">
                        <ApartmentList
                            state={this.props.state}
                            handleChange={this.handleChange}
                            size={2}
                        />
                    </Col>
                    <Col md={6}>
                        <div style={{ position: 'sticky', top: 0 }}>
                            <LeipzigMap
                                state={this.props.state}
                                handlechange={this.handleChange}
                                filterData={this.filterData}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Booking;
