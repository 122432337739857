/*
    Carousel Lea rendert Bilder anhand des Datenfelds "bilder_anz",
    sind keine Bilder vorhanden so wird als Beispiel ein zufälliges aus
    dem Ordner _noPhotos ausgewählt
*/

import React from "react"
import Carousel from "react-bootstrap/Carousel";
import './carousel.css'

class CarouselLea extends React.Component{
    determineImagePath(){
        // sucht nach Bilder im Pfad der in über die ID im Public Ordner aufrufbar ist
        return process.env.PUBLIC_URL + '/apartments/' + this.props.apartment.id + "/" + this.props.apartment.id + "_";
    }


    render(){
        const {maxImageCount, apartment} = this.props
        const defaultImageCount = 4;
        const effectiveMaxImages = maxImageCount && maxImageCount < apartment.bilder_anz ? maxImageCount : apartment.bilder_anz;

        const bilderAnzahl = apartment.bilder_anz !== null
            ? new Array(parseInt(effectiveMaxImages)).fill(true)
            : new Array(defaultImageCount).fill(true)

        return(
           <Carousel>
               {
                   bilderAnzahl.map((bild, index) => {
                       let src = apartment.bilder === "ja" ?
                           `${this.determineImagePath()}${index + 1}.jpg` :
                           `${process.env.PUBLIC_URL}/apartments/_noPhotos/${index + 1}.jpg`;
                       console.log(process.env.PUBLIC_URL);
                       return (
                           <Carousel.Item
                               key={index}
                               interval={this.props.interval || Math.floor(Math.random() * 10000) + 5000}>
                               <img
                                   className="d-block w-100 apartmentCardImage"
                                   src={src}
                                   alt={this.props.apartment.id}
                               />
                               {this.props.apartment.bilder !== "ja" && (
                                   <Carousel.Caption>
                                       <h5>Beispielbild</h5>
                                   </Carousel.Caption>
                               )}
                           </Carousel.Item>
                       );
                   })
               }
           </Carousel>
        )
    }
}

export default CarouselLea
