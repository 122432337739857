import React from "react"
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from "react-router-dom"
import "./footer.css"  // Stellen Sie sicher, dass Sie Ihre CSS entsprechend anpassen
import logo from "../../../assets/img/layout/logo_new.png"
import { Facebook, Twitter, Instagram, Linkedin } from 'react-bootstrap-icons'

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Aktuelles Jahr

  return (
      <div className="footer">
        <Container>
          <div className="line"></div>
          <Row className="footer-row">

            <Col xs={12} md={4} className="footer-col">
              <Image src={logo} className="img" fluid/>
            </Col>

            <Col xs={6} md={4} className="footer-col">
              <ul className="footer-list">
                <li><Link to="/aboutus" className="footer-link">Team</Link></li>
                <li><Link to="/impressum" className="footer-link">Impressum</Link></li>
                <li><Link to="/privacy" className="footer-link">Datenschutz</Link></li>
                <li><Link to="/withdrawal" className="footer-link">Widerrufsbelehrung</Link></li>
              </ul>
            </Col>

            <Col xs={6} md={4} className="footer-col">
              <ul className="footer-list">
                <li><Link to="/contact" className="footer-link">Kontakt</Link></li>
                <li>+49 160 966 04 793</li>
                <li>jochen-laessig@t-online.de</li>
                <Row className="social-icons">
                  <Col><Facebook /></Col>
                  <Col><Twitter /></Col>
                  <Col><Linkedin /></Col>
                  <Col><Instagram /></Col>
                </Row>
              </ul>
            </Col>

          </Row>

          <Row>
            <p> </p>
          </Row>

          <Row>
            <p> </p>
          </Row>

          <Row>
            <Col>
              <p className="text-center copyright">Copyright © {currentYear} Alle Rechte liegen bei Jochen Läßig</p>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

export default Footer
