import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ApartmentCard from './ApartmentCard'
import './apartmentList.css'

class ApartmentList extends React.Component {
    constructor(props){
        super(props)
        this.filterData = this.filterData.bind(this)
    }

    filterData(event){
        this.props.filterData(event)
    }

    render() {
        const containerClass = this.props.size === 3
            ? "apartmentList_Container"
            : "apartmentList_ContainerBigScreen";
        const cardSize = 12 / this.props.size;

        return (
            <div className={containerClass}>
                <Row className="justify-content-center">
                    <Col sm={8}><h1 className="apartmentList_heading">Unsere Apartments</h1></Col>
                </Row>
                <br/>
                <Row>
                    {this.props.state.data.map((apartment, index) => {
                        if (apartment.id === "buero") return null;
                        return (
                            <Col key={index} xl={cardSize}>
                                <ApartmentCard apartment={apartment} key={apartment.id}/>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    }
}

export default ApartmentList
