import './App.css';
import React from 'react';
import Store from './components/Store'

function App() {
  return (
      <Store />
  );
}

export default App;
