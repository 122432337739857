// TODO* Personenanzahl-Select als Range implementieren und auf 30 erhöhen

import React from 'react'
import { Button, Container, Col, Row, Form, FormControl } from 'react-bootstrap'
import DatePickerLea from "./DatePickerLea"
import "react-datepicker/dist/react-datepicker.css";
import './selectBox.css'
import {Link} from "react-router-dom";

class SelectBox extends React.Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.state = {
            widthItem:10,
            widthBorder: 1
        }
    }

    handleChange(event) {
        this.props.handleChange(event)
    }

    render() {
        return (
                <Container fluid>
                    <div className="selectBoxContainer">
                        <Row>
                            <Col sm={this.state.widthBorder}></Col>
                            <Col sm={this.state.widthItem}>
                            <h1>Finde dein passendes Zimmer</h1>
                        </Col><Col sm={this.state.widthBorder}></Col>
                        </Row>
                        <Form className="selectBoxForm">
                            <Form.Group>
                                {/*===================== PERSONEN ANZAHL =====================*/}
                                <Row>
                                    <Col md={1}></Col>
                                    <Col md={3}>
                                        <Form.Label>{this.props.state.personenAnzahl} Personen</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                        <Form.Control
                                            type="range"
                                            id="personenAnzahl"
                                            value = { this.props.state.personenAnzahl }
                                            onChange = { this.handleChange }
                                            min = { 1 }
                                            max = { 60 }
                                            step = { 1 }
                                            custom
                                            />
                                    </Col>
                                    <Col md={1}></Col>
                                </Row>
                                {/*===================== STADTTEIL =====================*/}
                                <Row>
                                    <Col sm={this.state.widthBorder}></Col>
                                    <Col sm={this.state.widthItem}>
                                        <FormControl
                                            as="select"
                                            id="stadtteil"
                                            value={this.props.state.stadtteil}
                                            onChange={this.handleChange}>
                                            <option value="Leipzig">Leipzig</option>
                                            <option value="Zentrum">Zentrum</option>
                                            <option value="Nord">Nord</option>
                                            <option value="West">West</option>
                                            <option value="Ost">Ost</option>
                                            <option value="Süd">Süd</option>
                                        </FormControl>
                                    </Col>
                                    <Col sm={this.state.widthBorder}></Col>
                                </Row>
                                {/*===================== ZIMMER ANZAHL =====================*/}
                                <Row>
                                    <Col sm={this.state.widthBorder}></Col>
                                    <Col sm={this.state.widthItem}>
                                        <FormControl
                                            as="select"
                                            id="zimmerAnzahl"
                                            value={this.props.state.zimmerAnzahl}
                                            onChange={this.handleChange}>
                                            <option value="1">1 Wohnung</option>
                                            <option value="2">2 Wohnungen</option>
                                            <option value="3">3 Wohnungen</option>
                                            <option value="4">4 Wohnungen</option>
                                            <option value="5">5 Wohnungen</option>
                                            <option value="6">6 Wohnungen</option>
                                            <option value="7">7 Wohnungen</option>
                                            <option value="8">8 Wohnungen</option>
                                            <option value="9">9 Wohnungen</option>
                                            <option value="10">10 Wohnungen</option>
                                            <option value="11">11 Wohnungen</option>
                                            <option value="12">12 Wohnungen</option>
                                            <option value="13">13 Wohnungen</option>
                                        </FormControl>
                                    </Col>
                                    <Col sm={this.state.widthBorder}></Col>
                                </Row>
                                {/*===================== BADEZIMMER ANZAHL =====================*/}
                                <Row>
                                    <Col sm={this.state.widthBorder}></Col>
                                    <Col sm={this.state.widthItem}>
                                        <FormControl
                                            as="select"
                                            id="bettenAnzahl"
                                            value={this.props.state.bettenAnzahl}
                                            onChange={this.handleChange}>
                                            <option value="1">1 Bett</option>
                                            <option value="2">2 Betten</option>
                                            <option value="3">3 Betten</option>
                                            <option value="4">4 Betten</option>
                                            <option value="5">5 Betten</option>
                                            <option value="6">6 Betten</option>
                                            <option value="7">7+ Betten</option>
                                        </FormControl>
                                    </Col>
                                    <Col sm={this.state.widthBorder}></Col>
                                </Row>
                                {/*===================== ANREISE =====================*/}
                                <Row>
                                    <Col sm={1}></Col>
                                    <Col sm={5}>
                                        <DatePickerLea
                                            state={this.props.state.anreise}
                                            handleChange={this.handleChange}
                                            id="anreise"
                                            title="Anreise"
                                        />
                                    </Col>

                                {/*===================== ABREISE =====================*/}

                                    <Col sm={5}>
                                        <DatePickerLea
                                            state={this.props.state.abreise}
                                            handleChange={this.handleChange}
                                            minDate={this.props.state.anreise}
                                            id="abreise"
                                            title="Abreise"
                                        />
                                    </Col>
                                    <Col sm={1}></Col>
                                </Row>
                                {/*===================== SUCHEN =====================*/}
                                <Row>
                                    <Col></Col>
                                    <Col sm={this.state.widthItem}>
                                        <Link to="/booking">
                                            <Button
                                                block
                                                className="btn_suchen"
                                                variant="primary">Suchen
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Form.Group>
                        </Form>
                    </div>
                </Container>
        )
    }
}

export default SelectBox
