import React from 'react'
import { Link } from "react-router-dom"

export default function Dropdown({isOpen, toggle}) {
    return (
        <div className={isOpen ? "text-center" : "d-none"} onClick={toggle}>
            <Link to="/" className="nav-link" name="booking">Home</Link>
            <Link to="/booking" className="nav-link" name="booking">Wohnungen</Link>
            <Link to="/aboutus" className="nav-link">Team</Link>
            <Link to="/contact" className="nav-link">Kontakt</Link>
        </div>
    )
}
