import React, {Component} from 'react';
import './numberDisplay.css'
import * as Icon from "react-bootstrap-icons";
import { Col, Row, Container} from "react-bootstrap";


class NumberDisplay extends Component {
    render() {
        return (
            <Container className="numberDisplay " fluid>
                <Row>
                    <Col className="numberDisplay_icon hvr-skew-forward">
                        <Icon.Phone size={20} />
                    </Col>
                    <Col className="numberDisplay_number hvr-skew-backward">
                        <p> +49 160 966 04 793 </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default NumberDisplay;
