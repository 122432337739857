import React from 'react'
import EmployeeCard from './EmployeeCard.js'
import BenefitsCard from './BenefitsCard.js'
import {Container, Row, Col } from 'react-bootstrap'
import 'react-bootstrap-icons';
import {EmojiLaughing, Cloud, Clipboard, Flower1, Server, HandThumbsUp} from 'react-bootstrap-icons';
import PartnerLogo from "./PartnerLogo";
import BookingComLogo from '../../../assets/img/partners/c-logo-4.jpg'
import AirBnBLogo from '../../../assets/img/partners/c-logo-1.jpg'
import MonteurzimmerLogo from '../../../assets/img/partners/c-logo-2.jpg'
import FairschlafenLogo from '../../../assets/img/partners/c-logo-3.jpg'
import JochenPortrait from "../../../assets/img/team/team-1.jpg"
import "./aboutus.css"



class AboutUs extends React.Component {

  render() {
    return (
      <Container fluid="md">
        <div className="margin_small"></div>
        <h2  className="text-center" >Das sind wir!</h2>
        <Row>
          <Col><EmployeeCard img={JochenPortrait} name="Jochen" job="CEO & Founder"/></Col>
          <Col md={3}><EmployeeCard name="Minett" job="House Keeping Management"/></Col>
          <Col md={3}><EmployeeCard name="Yves" job="Room Service"/></Col>
          <Col md={3}><EmployeeCard name="Thorsten" job="Care-Taker"/></Col>
        </Row>

        <br/>

        <h2 className="text-center" >Warum wir die Richtigen sind!</h2>
        <h4 className="text-center" >Durch langjährige Erfahrung wissen wir, worauf unsere Kunden Wert legen!</h4>

        <Row>
          <Col md={4}>
            <BenefitsCard
                title="Unschlagbarer Preis"
                description="Die Balance zwischen Kosten, Leistungen und Konkurrenzpreisen ermöglichen es uns
                            Ihnen Apartments ab 11€/Nacht anbieten zu können. Dennoch steht Qualität bei uns ganz
                            oben auf der Agenda."
                icon={<EmojiLaughing/>} />
          </Col>
          <Col md={4}>
            <BenefitsCard
                title="Garantierter Schlafkomfort"
                description="Nur wer erholsam schläft, kann volle Leistungen erbringen.
                            Daher investieren wir besonders in modernste Matratzentechnik und Lattenroste.
                            Gleichzeitig besitzen all unsere Aparments schallisolierte Wände, verdichtete Fenster sowie Rolläden oder Jalousien."
                icon={<Cloud />}/>
          </Col>
          <Col md={4}>
            <BenefitsCard
                title="Komplette Ausstattung"
                description="Unsere Apartments werden stetig modernisiert und den Alltagsbedürfnissen angepasst.
                            So verfügt jede unserer Wohnungen über 50Mbit-WLAN, einen Smart-TV sowie jegliche Alltagsgegenstände
                            (Waschmaschine, Kaffeemaschine, Wasserkocher, Mikrowelle, Herd etc.)."
                icon={<Clipboard />}/>
          </Col>
        </Row>

        <br/>

        <Row>
          <Col md={4}>
            <BenefitsCard
                title="Traumhafte Lage"
                description="Ob im Zentrum oder am Stadtrand, Leipzig bietet viele Praks, Grünanlagen und Sehenswürdigkeiten.
                            Einer unserer Standard Governance-Regeln besagt, dass alle lebensnotwendigen Einrichtungen im Umkreis von 300m
                            erreichbar sein müssen; und Natur und Erholung empfinden wir als lebensnotwendig."
                icon={<Flower1 />}/>
          </Col>
          <Col md={4}>
            <BenefitsCard
                title="Qualifizierter Service"
                description="Regelmäßige Reinigung gehört zu unseren Standards und muss nicht extra hinzugebucht werden.
                              Wir sorgen dafür, dass unser Personal nicht nur richtig geschult, sondern auch hochmotiviert ist."
                icon={<Server />}/>
          </Col>
          <Col md={4}>
            <BenefitsCard
                title="Positive Reviews"
                description="Dank langjähriger Erfahrung in der Apartmentvermietung,
                              haben wir gelernt, auf was unsere Kunden Wert legen. Feedback und Reviews haben uns
                              geholfen Ihnen unsere Leistungen heute zu einem unschlagbaren Preis anbieten zu können."
                icon={<HandThumbsUp />}/>
          </Col>
        </Row>

        <br/>

        <h2  className="text-center" >Unsere Partner</h2>

        <br/>

        <Row>
            <Col md={3}>
                <PartnerLogo
                    src={AirBnBLogo}
                    href="https://www.airbnb.de/"
                    alt="AirBnB"
                />
            </Col>
            <Col md={3}>
                <PartnerLogo
                    src={FairschlafenLogo}
                    href="https://www.ferienwohnungleipzig.com/"
                    alt="Fairschlafen"
                />
            </Col>
            <Col md={3}>
                <PartnerLogo
                    src={MonteurzimmerLogo}
                    href="https://www.monteurzimmer.de/"
                    alt="Monteurzimmer"
                />
            </Col>
            <Col md={3}>
                <PartnerLogo
                    src={BookingComLogo}
                    href="https://www.booking.com/"
                    alt="Booking.com"
                />
            </Col>
        </Row>

    </Container>

    )
  }
}

export default AboutUs
