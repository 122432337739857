import React from 'react'
import { Image } from 'react-bootstrap'
import './banner.css'
import { useLocation } from 'react-router-dom'
import BannerHome from '../../../assets/img/layout/banner_home.jpg'
// import BannerGeneral from '../../../assets/img/layout/banner_lpz.jpg'
import BannerGeneral from '../../../assets/img/layout/banner_lpz_short.jpg'

function headingRouteMapping(route){
  // returned Title, Sutitle im Banner Header
  switch(route.pathname){
    case "/": return { title: "LEA Monteurszimmer", subTitle: "DEIN MONTEURSZIMMER ZUM WOHLFÜHLEN" }
    case "/booking": return {title: "Anfragen"}
    case "/aboutus": return { title: "Das sind wir" }
    case "/contact": return { title: "Jederzeit für Sie erreichbar" }
    case "/agb": return { title: "AGB"}
    case "/privacy": return { title: "Datenschutzbelehrung"}
    case "/impressum": return { title: "Impressum"}
    case "/withdrawal": return { title: "Widerrufsbelehrung"}
    default : return ""
  }
}

function imageRouteMapping(route, returnAltName = false){
  // returned anhand des Routenames einen src oder alt value
  switch(route.pathname){
    case "/": return returnAltName ? "Skyline Leipzig" : BannerHome;
    case "/booking": return returnAltName ? "Skyline Leipzig" : BannerGeneral;
    case "/aboutus": return returnAltName ? "Skyline Leipzig" : BannerGeneral;
    case "/contact": return returnAltName ? "Skyline Leipzig" : BannerGeneral;
    case "/agb": return returnAltName ? "Skyline Leipzig" : BannerGeneral;
    case "/privacy": return returnAltName ? "Skyline Leipzig" : BannerGeneral;
    case "/impressum": return returnAltName ? "Skyline Leipzig" : BannerGeneral;
    case "/withdrawal": return returnAltName ? "Skyline Leipzig" : BannerGeneral;
    default: return "";
  }
}

const Banner = () => {
  // useLocation ist eine react-router Funktion die alle Route-Parameter auswertet
  const route = useLocation()
  // Dynamische CSS Class je nach Banner Image
  const bannerImageClass = route.pathname === "/" ? "bannerImg" : "bannerImgSmall"
    return(
      // gibt Title und falls vorhanden Subtitle sowie Bild aus

      <div className="bannerContainer">
        <Image
          src={imageRouteMapping(route)}
          alt={imageRouteMapping(route,true)}
          className={bannerImageClass}
          fluid/>
        <h1 className="bannerTitle" >
          {headingRouteMapping(route).title}
        </h1>
        { headingRouteMapping(route).subTitle &&
        <h4 className="bannerSubTitle" >
          {headingRouteMapping(route).subTitle}
        </h4>
        }
      </div>
  )
}

export default Banner





