import React from 'react'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import Body from '../Body/Body'

class Layout extends React.Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.filterData = this.filterData.bind(this)
    }

    handleChange(event) {
        this.props.handleChange(event)
    }

    filterData(event){
        this.props.filterData(event)
    }

    render(){
        return(
            <React.Fragment>
                <Header/>
                <Body
                    state={this.props.state}
                    handleChange={this.handleChange}
                    filterData={this.filterData}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Layout
