import React from "react"
import logo from "../../../assets/img/layout/logo_new.png"
import {Navbar, Nav, Image, Container} from 'react-bootstrap'
import { Link } from "react-router-dom"
import "./navbar.css"

const NavBar = () => {
    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="justify-content-center">
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <Image src={logo} height="50px" fluid style={{maxHeight:" 80px", minHeight:"50px"}}/>
                    </Link>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Item className="spacing">
                            <Link to="/" className="nav-link">Home</Link>
                        </Nav.Item>
                        <Nav.Item className="spacing">
                            <Link to="/booking" className="nav-link">Wohnungen</Link>
                        </Nav.Item>
                        <Nav.Item className="spacing">
                            <Link to="/aboutus" className="nav-link">Team</Link>
                        </Nav.Item>
                        <Nav.Item className="spacing">
                            <Link to="/contact" className="nav-link">Kontakt</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
