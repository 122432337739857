import React from 'react'
import * as Icon from 'react-bootstrap-icons';
import './DataCard.css'

class DataCard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            iconSize: 32
        }
    }

    mapIconTextToIconComponent(iconText){
        switch(iconText){
            case 'apartment': return <Icon.House size={this.state.iconSize} className="dataCard_icon"/>
            case 'beds': return <Icon.Moon size={this.state.iconSize} className="dataCard_icon"/>
            case 'people': return <Icon.People size={this.state.iconSize} className="dataCard_icon"/>
            case 'rooms': return <Icon.DoorClosedFill size={this.state.iconSize} className="dataCard_icon"/>
            default: return <Icon.Moon size={this.state.iconSize} className="dataCard_icon" />
        }
    }

    render() {
        return (
            <div className="dataCard_card">
                {this.mapIconTextToIconComponent(this.props.icon)}
                <p className="dataCard_number">{this.props.number}</p>
                <p className="dataCard_text">{this.props.text}</p>
            </div>
        )
    }
}

export default DataCard
